import React from 'react';
import { Button } from '@mui/material';
import trashAndDatesAtom from '../../atoms/trashAndDatesAtom';
import { useRecoilState } from 'recoil';
import hAConfigYamlAtom from '../../atoms/hAConfigYamlAtom';

export default function ClearButton() {
  const [trashAndDates, setTrashAndDates] = useRecoilState(trashAndDatesAtom);
  const [yamlData, setYamlData] = useRecoilState(hAConfigYamlAtom);

  const handleOnClick = () => {
    setTrashAndDates([]);
    setYamlData('');
  };

  return (
    <Button variant="contained" color='secondary'
        disabled={trashAndDates.length === 0 && yamlData.length === 0}
        onClick={handleOnClick}>
          Resultaat wissen
    </Button>
  );
}