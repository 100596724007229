import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { muiTheme } from './theme/muiTheme';
import { ThemeProvider } from '@mui/material';
import './index.css';
import MainPage from './pages/MainPage';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
]);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={muiTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
);