import { createTheme } from '@mui/material';

const muiTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#2e0e54',
      },
      secondary: {
        main: '#388e3c',
      },
      background: {
        default: '#cfd8dc',
      },
    },
  });

  export {muiTheme};