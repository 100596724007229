import React from 'react';
import { useRecoilState } from 'recoil';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import isMenuOpenAtom from '../atoms/isMenuOpenAtom';
import { CalendarMonth, Home } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import './CustomDrawer.css';

export default function CustomDrawer() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenAtom);

  const handleClick = (url) => {
    navigate(url);
    setIsMenuOpen(false)
  };

  return (
    <Drawer
      open={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
    >
      <List>
        <ListItem className={'listitem'}>
          <ListItemButton onClick={() => {
            handleClick('/');
          }}>
            <ListItemIcon>
              <CalendarMonth/>
            </ListItemIcon>
            <ListItemText primary={'Kalender'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}