import { nanoid } from 'nanoid'
import { addOneDay } from './addOneDay';

/**
 * Generate all the ics events
 */
const generateIcalEvents = (trashAndDates) => {
    const tempIcalEvents = [];
    trashAndDates.forEach(e => {
        tempIcalEvents.push(_iCalEvent(e.date.split('T')[0] ,e.name));
    });
    return tempIcalEvents;
}

/**
 * Returns an ics event
 */
const _iCalEvent = (start, title) => {
    const startDate = new Date(start).toISOString();
    const splittedStartDate = startDate.split('-');
    const endDate = addOneDay(new Date(start)).toISOString();
    const splittedEndDate = endDate.split('-');

    return {
        productId:"4fv4l.nl",
        uid: nanoid(),
        startOutputType:"local",
        start: [Number(splittedStartDate[0]),Number(splittedStartDate[1]),Number(splittedStartDate[2].split('T')[0])],
        end: [Number(splittedEndDate[0]),Number(splittedEndDate[1]),Number(splittedEndDate[2].split('T')[0])],
        title: title
    }
};

export {generateIcalEvents};