import FullCalendar from '@fullcalendar/react' // must go before plugins
import multiMonthPlugin from '@fullcalendar/multimonth';
import './CalendarComp.css';
import { Box } from '@mui/system';
import { useRecoilValue } from 'recoil';
import trashAndDatesAtom from '../atoms/trashAndDatesAtom';
import { useEffect, useState } from 'react';
import isLoadingAtom from '../atoms/isLoadingAtom';

export default function CalendarComp() {
  const isLoading = useRecoilValue(isLoadingAtom);
  const trashAndDates = useRecoilValue(trashAndDatesAtom);
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    if(trashAndDates.length > 0) {
      setCalendarEvents(
        trashAndDates.map((res) => ({
          title: res.name.length <= 3 ? res.name.toUpperCase() : res.name,
          start: res.date.split('T')[0],
          backgroundColor:
            res.name.toLowerCase() === 'cleanprofsgft' ? '#90EE90' : 
            res.name.toLowerCase() === 'cleanprofspbd' ? '#FF0000' : 
            res.name.toLowerCase() === 'cleanprofsrestafval' ? '#444444' : 
            res.name.toLowerCase() === 'gft' ? 'green' : 
            res.name.toLowerCase() === 'grofvuil' ? 'lightgrey' : 
            res.name.toLowerCase() === 'kca' ? 'purple' :
            res.name.toLowerCase() === 'kerstboom' ? 'darkgreen' :
            res.name.toLowerCase() === 'papier' ? 'blue' : 
            res.name.toLowerCase() === 'pbd' ? 'orange' :
            res.name.toLowerCase() === 'restafval' ? 'grey' :
            res.name.toLowerCase() === 'takken' ? 'brown' :
            res.name.toLowerCase() === 'textiel' ? 'lightblue' : 'black'
        }))
      );
    } else {
      setCalendarEvents([]);
    }
  },[trashAndDates]);

  return (
    <>
    {!isLoading && trashAndDates.length > 0 &&
      <Box className={'calendar-comp'}>
          <Box sx={{margin: 1, marginTop: 1}}>
            <FullCalendar
              headerToolbar={{start: 'title', end: 'prev,next'}}
              plugins={[ multiMonthPlugin ]}
              initialView="multiMonthYear"
              locale={'NL-nl'}
              events={calendarEvents}
              multiMonthMaxColumns ="2" // force 2 columns wide
            />
          </Box>
      </Box>
    }
    </>
  );
}