import React from 'react';
import { Button } from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import trashAndDatesAtom from '../../atoms/trashAndDatesAtom';
import isLoadingAtom from '../../atoms/isLoadingAtom';
import locationAtom from '../../atoms/locationAtom';
import zipCodeAtom from '../../atoms/zipCodeAtom';
import houseNumberAtom from '../../atoms/houseNumberAtom';
import houseNumberSuffixAtom from '../../atoms/houseNumberSuffixAtom';
import districtAtom from '../../atoms/districtAtom';
import { getDates } from '../../services/trashapiService';

export default function SearchButton() {
  const setTrashAndDates = useSetRecoilState(trashAndDatesAtom);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingAtom);

  const location = useRecoilValue(locationAtom);
  const zipCode = useRecoilValue(zipCodeAtom);
  const houseNumber = useRecoilValue(houseNumberAtom);
  const houseNumberSuffix = useRecoilValue(houseNumberSuffixAtom);
  const district = useRecoilValue(districtAtom);

  const handleOnClick = () => {
    setIsLoading(true);
    getDates(location, true, zipCode, houseNumber, houseNumberSuffix, district).then((res) => {
      setTrashAndDates(res);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  };

  return (
    <Button variant="contained"
      disabled={
        isLoading || 
        ((location?.toLowerCase() !== 'maassluis' && location?.toLowerCase() !== 'ouder-amstel') && (!zipCode || !houseNumber)) ||
        ((location?.toLowerCase() === 'maassluis' || location?.toLowerCase() === 'ouder-amstel') && !district)
      }
      onClick={handleOnClick}>
        Zoeken
    </Button>
  );
}