import React from 'react';
import { useRecoilValue } from 'recoil';

import { Watch } from  'react-loader-spinner'
import { Box } from '@mui/material';

import './LoadingIndicator.css';
import isLoadingAtom from '../atoms/isLoadingAtom';

export default function LoadingIndicator() {
    const isLoading = useRecoilValue(isLoadingAtom);

    return (
    <>
        {isLoading &&
            <Box className="loading-indicator-wrapper">
                <Watch
                    height="160"
                    width="160"
                    radius="48"
                    color="#2e0e54"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    visible={true}
                    />
            </Box>
        }
    </>
  );
}