import axios from "axios";

const errorEvent = new CustomEvent("error", { detail: "Fout bij ophalen van data. Geen resultaten gevonden" });

const getDates = (location, showWholeYear, zipCode, houseNumber, houseNumberSuffix, district) => {
    let promise;

    if(location && (location.toLowerCase() === "maassluis" || location.toLowerCase() === "ouder-amstel")) {
        promise = axios.get(
            process.env.REACT_APP_BASE_URL + 
            "/trash?Location=" + location +
            "&District=" + district +
            "&ShowWholeYear=" + showWholeYear);
    }
    else {
        promise = axios.get(
            process.env.REACT_APP_BASE_URL + 
            "/trash" + (location ? "?Location=" + location + "&" : "?") +
            "ZipCode=" + zipCode +
            "&HouseNumber=" + houseNumber +
            "&HouseNumberSuffix=" + houseNumberSuffix +
            "&ShowWholeYear=" + showWholeYear);
    }
    
        
    const dataPromise = promise.then((res) => {
        return res.data;
    }).catch((err) => {
        window.dispatchEvent(errorEvent);
        return [];
    });
    return dataPromise;
};

const getAllLocations = () => {
    const promise = axios.get(process.env.REACT_APP_BASE_URL + "/trash/getAllLocations");
    const dataPromise = promise.then((res) => {
            return res.data;
    }).catch((err) => {
        window.dispatchEvent(errorEvent);
        return [];
    });
    return dataPromise;
};

export {getDates, getAllLocations};