import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { TextField, Autocomplete, Grid } from '@mui/material';
import { getAllLocations } from '../../services/trashapiService';
import './AddressFields.css';
import { useRecoilState } from 'recoil';
import locationsAtom from '../../atoms/locationsAtom';
import locationAtom from '../../atoms/locationAtom';
import zipCodeAtom from '../../atoms/zipCodeAtom';
import houseNumberAtom from '../../atoms/houseNumberAtom';
import houseNumberSuffixAtom from '../../atoms/houseNumberSuffixAtom';
import districtAtom from '../../atoms/districtAtom';

export default function AddressFields(props) {
  const [locations, setLocations] = useRecoilState(locationsAtom);
  const [partialLocation, setPartialLocation] = useState("");
  const [location, setLocation] = useRecoilState(locationAtom);
  const [zipCode, setZipCode] = useRecoilState(zipCodeAtom);
  const [houseNumber, setHouseNumber] = useRecoilState(houseNumberAtom);
  const [houseNumberSuffix, setHouseNumberSuffix] = useRecoilState(houseNumberSuffixAtom);
  const [district, setDistrict] = useRecoilState(districtAtom);

  useEffect(() => {
    getAllLocations().then((res) => {
      setLocations(res);
    });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={'address-fields'}>
      <Grid container rowSpacing={1}>
        <Grid item>
          <Autocomplete
            disablePortal
            defaultValue=""
            id="location-autocomplete"
            options={locations}
            sx={{ width: 300, display: 'contents' }}
            value={location}
            onChange={(e, newValue) => {
              setLocation(newValue);
            }}
            inputValue={partialLocation}
            onInputChange={(event, newInputValue) => {
              setPartialLocation(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} sx={{width: "240px", marginRight: 1}} size="small" label={`Gemeente ${props.gemeenteRequired ? "*" : ""}`} />}
            />
        </Grid>
        {(location?.toLowerCase() !== 'maassluis' && location?.toLowerCase() !== 'ouder-amstel') &&
        <>
          <Grid item>
            <TextField
              required={true}
              size="small"
              className='text-field'
              sx={{width: "116px", marginRight: 1}}
              label="Postcode"
              value={zipCode}
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              required={true}
              size="small"
              className='text-field'
              sx={{width: "76px", marginRight: 1}}
              label="Huisnr"
              value={houseNumber}
              onChange={(e) => {
                setHouseNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              size="small"
              className='text-field'
              sx={{width: "76px", marginRight: 2}}
              label="Toevoeging"
              value={houseNumberSuffix}
              onChange={(e) => {
                setHouseNumberSuffix(e.target.value);
              }}
            />
          </Grid>
        </>
        }
        {(location?.toLowerCase() === 'maassluis' || location?.toLowerCase() === 'ouder-amstel') &&
        <Grid item>
          <TextField
            required="true"
            size="small"
            className='text-field'
            sx={{width: "164px", marginRight: 2}}
            label="Buurt"
            value={district}
            onChange={(e) => {
              setDistrict(e.target.value);
            }}
          />
        </Grid>
        }
      </Grid>
    </Box>
  );
}