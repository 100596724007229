import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CalendarComp from '../components/CalendarComp';
import ClearButton from '../components/buttons/ClearButton';
import CustomAppBar from '../components/CustomAppBar';
import ErrorSnackbar from '../components/ErrorSnackbar';
import LoadingIndicator from '../components/LoadingIndicator';
import SearchButton from '../components/buttons/SeachButton';
import AddressFields from '../components/fields/AddressFields';
import { setWindowSize } from '../helpers/windowSize';
import CustomDrawer from '../components/CustomDrawer';


export default function MainPage() {
  useEffect(() => {
    setWindowSize();
  }, []);

  return (
    <>
      <CustomDrawer/>
      <CustomAppBar title={"Kalender"} />
      <Grid container rowSpacing={1} sx={{margin: 1, width: "auto"}}>
        <Grid sx={{paddingTop: '0 !important'}} item xs={12}>
          <Typography sx={{marginBottom: '8px'}} variant='h6'>Test hier je adres voor de afvalkalender van afvalinfo</Typography>
        </Grid>
        <Grid item>
          <AddressFields gemeenteRequired={false}/>
        </Grid>
        <Grid item sx={{marginRight: 1, display: "flex"}}>
          <SearchButton />
        </Grid>
        <Grid item sx={{display: "flex"}}>
          <ClearButton />
        </Grid>
      </Grid>
      <LoadingIndicator />
      <CalendarComp />
      <ErrorSnackbar />
    </>
  );
}