import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useRecoilState, useRecoilValue } from 'recoil';
import trashAndDatesAtom from '../atoms/trashAndDatesAtom';
import { createEvents} from 'ics';
import locationAtom from '../atoms/locationAtom';
import zipCodeAtom from '../atoms/zipCodeAtom';
import houseNumberAtom from '../atoms/houseNumberAtom';
import houseNumberSuffixAtom from '../atoms/houseNumberSuffixAtom';
import { Button, IconButton } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { generateIcalEvents } from '../helpers/icalHelper';
import MenuIcon from '@mui/icons-material/Menu';
import './CustomAppBar.css';
import isMenuOpenAtom from '../atoms/isMenuOpenAtom';

export default function CustomAppBar(props) {
  const trashAndDates = useRecoilValue(trashAndDatesAtom);
  const location = useRecoilValue(locationAtom);
  const zipCode = useRecoilValue(zipCodeAtom);
  const houseNumber = useRecoilValue(houseNumberAtom);
  const houseNumberSuffix = useRecoilValue(houseNumberSuffixAtom);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenAtom);
  const [icalUrl, setIcalUrl] = React.useState('');
  const [icalFileName, setIcalFileName] = React.useState('');
  const [icalEvents, setIcalEvents] = React.useState([]);

  /**
   * When the trashAndDates are known, generate the events
   */
  useEffect(() => {
    if(trashAndDates.length > 0) {
      setIcalEvents(generateIcalEvents(trashAndDates));
    }
  }, [trashAndDates]);

  /**
   * When the events are known, create the ics file in memory
   */
  useEffect(() => {
    if(icalEvents.length > 0) {
      createIcalFile();
    }
  }, [icalEvents]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Create the ics file in memory
   */
  const createIcalFile = async() => {
    const filename = location ?? '' + '_' + zipCode + '_' + houseNumber + '_' + houseNumberSuffix + '.ics';
    setIcalFileName(filename);
    const file = await new Promise((resolve, reject) => {
      createEvents(icalEvents, (error, value) => {
        if (error) {
           reject(error)
        }
        resolve(new File([value], filename, { type: 'plain/text' }))
      });
    })
    setIcalUrl(URL.createObjectURL(file));
  };

  const iCalButton = (
    <Button 
      variant="contained" 
      endIcon={<CalendarMonthIcon />} 
      href={icalUrl}
      download={icalFileName}
      >
      iCal
    </Button>
  );

  return (
    <AppBar className='appbar' position="static">
      <Toolbar>
        {/* <IconButton className='menu-button' aria-label="menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <MenuIcon />
        </IconButton> */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 1 }}>
          {props.title}
        </Typography>
        {trashAndDates?.length > 0 &&
          iCalButton
        }
      </Toolbar>
    </AppBar>
  );
}