import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

export default function ErrorSnackbar() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.addEventListener("error", handleErrorEvent);
    return () => {
      window.removeEventListener("error", handleErrorEvent);
    };
  }, []);

  const handleErrorEvent = (event) => {
    setMessage(event.detail);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  return (
    <Snackbar
      open={open}
      color="primary"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={5000}
    >
      <Alert severity="error">{message}</Alert>
    </Snackbar>
  );
}